import React, { useEffect, useRef } from 'react';
import Page from 'Elements/Page';
import 'Styles/OrderStatusPage.scss';
import { Icon } from '@iconify/react';
import { getStatusList } from '../utils/getOrderStatus';
import { DELIVERY_TYPES_MAP, ORDER_STATUS_MAP } from '../utils/constants';
import { BarFooter } from 'Elements/BarFooter';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import EventsManager from 'Controllers/EventsManager';
import ApiServices from 'ApiServices/ApiServices';
import { DeliveryRider } from 'Components/DeliveryRider';
import { BankingInformation } from 'Components/BankingInformation';
import { formatCurrency } from '../functions/money';

const carouselSettings = {
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 1,
  centerMode: false,
  variableWidth: true,
  slidesToScroll: 2,
};

function OrderStatusPage({
  data: { activeOrders, activeOrder, commerceCountryCode, commercePhone, commerceName, bankingInformation, commerceID },
}) {
  const [currentOrder, setCurrentOrder] = React.useState(0);
  const order = activeOrders[currentOrder];
  const isDeliveryByProviders = order?.deliveryType === DELIVERY_TYPES_MAP.delivery_providers;
  const statusList = getStatusList(order?.deliveryMethod, isDeliveryByProviders);
  const activeOrdersLength = activeOrders.length;
  const hour = new Date(activeOrders[currentOrder].hour).toLocaleTimeString([], { hour: 'numeric', minute: 'numeric' });
  const date = new Date(activeOrders[currentOrder].hour).toLocaleDateString();
  const eventsManager = EventsManager.giveEventsManager();
  const slickRef = useRef(null);
  const deliveryDriverInfo = activeOrders[currentOrder]?.deliveryDriverInfo;
  const deliveryTrackingUrl = activeOrders[currentOrder]?.deliveryTrackingUrl;
  const orderTotal = activeOrders[currentOrder]?.orderTotal;
  const paymentMethod = activeOrders[currentOrder]?.paymentMethod;

  useEffect(() => {
    if (activeOrder) {
      setCurrentOrder(activeOrder);

      if (slickRef.current) {
        slickRef.current.slickGoTo(activeOrder, false);
      }
    }
  }, [activeOrder]);

  return (
    <>
      <Page id="Order_Status">
        <div className="order_status_page">
          <div className="order_status_page-wrap">
            <h1 className="order_status_page-title">Estatus de pedidos</h1>
            <p className="order_status_page-description">
              Tienes <b>{activeOrdersLength}</b> pedido{activeOrdersLength !== 1 && 's'} activo
              {activeOrdersLength !== 1 && 's'}
            </p>
            <div className="order_status_page-carousel">
              <Slider {...carouselSettings} className="order_status_page-carousel-slider" ref={slickRef}>
                {activeOrders.map((activeOrderItem, index) => {
                  const isActive = index === currentOrder;
                  return (
                    <div
                      key={activeOrderItem.id}
                      className="order_status_page-carousel-item"
                      onClick={() => setCurrentOrder(index)}
                    >
                      <div className={`order_status_page-carousel-wrapper ${isActive ? 'item-active' : ''}`}>
                        <div className="order_status_page-carousel-icon">
                          <Icon
                            className={isActive ? 'active' : 'unactive'}
                            icon={ORDER_STATUS_MAP[activeOrderItem.status]?.icon}
                            width="18px"
                            height="18px"
                            // color={isActive ? '#ffffff' : '#4298B5'}
                          />
                        </div>
                        <div>
                          <p className="order_status_page-carousel-label">{activeOrderItem.orderId}</p>
                          {isActive && <div className="order_status_page-carousel-active-bar"></div>}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
            {paymentMethod === 'Transferencia' && bankingInformation.clabe ? (
              <section style={{ marginBottom: '-13px', marginTop: '-5px' }}>
                <h2 style={{ marginBottom: '8px' }}>Asegurate de transferir {formatCurrency(orderTotal)} a:</h2>
                <BankingInformation
                  name={bankingInformation?.name}
                  bank={bankingInformation?.bank}
                  clabe={bankingInformation.clabe}
                  commerceName={commerceName}
                  commerceID={commerceID}
                />
              </section>
            ) : null}
            <h2>Sigue el estatus de tu pedido</h2>
            <div className="order_status_page-date">
              <p>
                <b>Fecha:</b> {date}
              </p>
              <p>
                <b>Hora:</b> {hour}hrs
              </p>
            </div>
            <div className="order_status_page-status">
              {statusList?.map((item, index) => {
                const isActive = item === order.status;
                const isDone = !isActive && statusList.indexOf(order.status) > index;
                const isPending = !isActive && statusList.indexOf(order.status) < index;

                const classToApply = isActive ? 'step-active' : isDone ? 'step-done' : isPending ? 'step-pending' : '';
                const iconClassName = isActive ? 'active' : isDone ? 'done' : isPending ? 'pending' : '';

                return (
                  <div className={`order_status_page-status-item ${classToApply}`} key={index}>
                    <div className="order_status_page-status-item-icon-container">
                      <div className="order_status_page-status-item-icon-container-icon">
                        <Icon icon={ORDER_STATUS_MAP[item].icon} width="18px" height="18px" className={iconClassName} />
                      </div>
                    </div>
                    <div className="order_status_page-status-item-description">
                      <p>{ORDER_STATUS_MAP[item].label}</p>
                    </div>
                  </div>
                );
              })}
            </div>
            {isDeliveryByProviders && <DeliveryRider deliveryDriverInfo={deliveryDriverInfo} />}
            <div className="order_status_page-buttons">
              {isDeliveryByProviders && deliveryTrackingUrl ? (
                <div
                  className="order_status_page-buttons-secondary"
                  onClick={() => window.open(deliveryTrackingUrl, '_blank')}
                >
                  <span>Ver ruta del repartidor</span>
                  <Icon icon="uil:angle-right" />
                </div>
              ) : null}
              <div
                className="order_status_page-buttons-secondary"
                onClick={() => {
                  ApiServices.sendWhatsappMessage(
                    commerceCountryCode,
                    commercePhone,
                    `Hola ${commerceName} tengo un pedido en curso con el FOLIO #${activeOrders[currentOrder]?.orderId}, quisiera saber su estatus`,
                  );
                }}
              >
                <span>Contactar al comercio</span>
                <Icon icon="uil:angle-right" />
              </div>
              <div className="order_status_page-buttons-main" onClick={() => eventsManager.publish('onGoToHome')}>
                <span>Hacer otro pedido</span>
                <Icon icon="uil:angle-right" />
              </div>
            </div>
          </div>
          <div className="bottom-footer">
            <BarFooter />
          </div>
        </div>
      </Page>
    </>
  );
}

export default OrderStatusPage;

OrderStatusPage.propTypes = {
  data: PropTypes.exact({
    activeOrders: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.string.isRequired,
        orderId: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        deliveryMethod: PropTypes.string.isRequired,
        paymentMethod: PropTypes.string.isRequired,
        orderTotal: PropTypes.number.isRequired,
        deliveryType: PropTypes.string.isRequired,
        hour: PropTypes.string.isRequired,
        deliveryDriverInfo: PropTypes.exact({
          name: PropTypes.string.isRequired,
          phone: PropTypes.number.isRequired,
          image: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
        }),
        deliveryTrackingUrl: PropTypes.string,
      }),
    ).isRequired,
    commercePhone: PropTypes.number.isRequired,
    commerceCountryCode: PropTypes.number.isRequired,
    commerceName: PropTypes.string.isRequired,
    commerceID: PropTypes.string.isRequired,
    activeOrder: PropTypes.number || null,
    bankingInformation: PropTypes.exact({
      name: PropTypes.string,
      bank: PropTypes.string,
      clabe: PropTypes.string,
    }),
  }),
};

/* eslint-disable complexity */
/* global ENV */
import { v4 as uuid } from 'uuid';
import ApiServices from 'ApiServices/ApiServices';
import EventsManager from 'Controllers/EventsManager';
import { formatCurrency } from '../functions/money';
import { DELIVERY_TYPES_MAP } from '../utils/constants';
import * as Sentry from '@sentry/browser';

const { por_kilometro, delivery_providers } = DELIVERY_TYPES_MAP;

class CartControllerInstance {
  constructor(commerceId) {
    this.commerceId = commerceId;
    this.cartList = [];
    this.commerceData = {};
  }

  registerCommerceData(data) {
    this.commerceData = data;
  }

  addProduct(data) {
    if (data.itemId) {
      //is update
      let index = this.cartList.findIndex((item) => item.itemId === data.itemId);
      if (index >= 0) this.cartList.splice(index, 1, data);
    } else this.cartList.push({ ...data, itemId: uuid() });
    let eventsManager = EventsManager.giveEventsManager();
    if (eventsManager) eventsManager.publish('onCartModified');
  }

  getProducts() {
    return this.cartList.map((item) => {
      let selectedOption = item.options.find((option) => option.count > 0);
      let selectedSections = item.sections
        .map((section) => section.content.filter((content) => content.count > 0))
        .flat();
      let selectedSuggestions = item.suggestions
        .map((suggestion) => suggestion.content.filter((content) => content.count > 0))
        .flat();
      return {
        id: item.id,
        categoryId: item.categoryId,
        name: item.name,
        count: item.count,
        itemId: item.itemId,
        option: selectedOption,
        sections: selectedSections,
        suggestions: selectedSuggestions,
        directions: item.directions,
      };
    });
  }

  getProductsLength() {
    return this.cartList.reduce((acc, item) => acc + item.count, 0);
  }

  getTotal() {
    return this.cartList.reduce(
      (total, product) =>
        total +
        product.count *
        (product.options.reduce((acc, item) => acc + item.price * item.count, 0) +
          product.sections.reduce(
            (acc, item) =>
              acc + item.content.reduce((acc2, cont) => (cont.price ? acc2 + cont.price * cont.count : acc2), 0),
            0,
          ) || 0) +
        product.suggestions.reduce(
          (acc, item) =>
            acc + item.content.reduce((acc2, cont) => (cont.price ? acc2 + cont.price * cont.count : acc2), 0),
          0,
        ),
      0,
    );
  }

  editProduct(itemId) {
    let cartItem = this.cartList.find((item) => item.itemId === itemId);
    if (cartItem) {
      let eventsManager = EventsManager.giveEventsManager();
      if (eventsManager) {
        eventsManager.publish('onAddProductPageRequest', cartItem);
        return new Promise((resolve) => {
          let token = eventsManager.subscribe('onPageExit', () => {
            if (eventsManager) {
              eventsManager.unsubscribe(token);
              eventsManager.publish('onCartModified');
            }
            resolve();
          });
        });
      }
    }
    return Promise.resolve();
  }

  deleteProduct(itemId) {
    let index = this.cartList.findIndex((elem) => elem.itemId === itemId);
    if (index >= 0) this.cartList.splice(index, 1);
    let eventsManager = EventsManager.giveEventsManager();
    if (eventsManager) eventsManager.publish('onCartModified');
  }

  buildOrder(preOrder, originObj) {
    const orderData = preOrder;

    Sentry.setContext('MenuContext', {
      origin: originObj?.origin,
      commerceId: this.commerceId,
      deliveryMethod: orderData?.deliveryMethod,
      payMethodType: orderData?.payMethod?.type,
      name: orderData.personalData?.name,
      phoneNumber: orderData?.personalData?.phoneNumber,
    });

    const deliveryPrice =
      orderData.deliveryMethod === 'delivery'
        ? orderData.freeDelivery
          ? 0
          : orderData.deliveryArea
          ? orderData.deliveryArea.price
          : orderData.deliveryPrice
        : 0;
    const subtotal = this.getTotal();
    const total = subtotal - orderData.discount + orderData.tips + deliveryPrice;
    const payMethod =
      orderData.payMethod.type === 'cash'
        ? 'Efectivo'
        : orderData.payMethod.type === 'card'
        ? 'Tarjeta'
        : orderData.payMethod.type === 'transfer'
        ? 'Transferencia'
        : orderData.payMethod.type === 'payment_provider'
        ? 'payment_provider'
        : orderData.payMethod.type === 'online'
        ? 'enlace_pago'
        : '';

    const order = {
      origin: originObj?.origin || 'web',
      address: orderData?.address,
      id_comercio: this.commerceId,
      metodo_entrega: orderData.deliveryMethod,
      delivery_type: orderData.deliveryMethod === 'delivery' ? orderData?.deliveryType : null,
      metodo_pago: payMethod,
      cliente_paga_con: orderData.payMethod.value,
      coupon_code: orderData.couponCode,
      numero_celular: orderData.personalData.phoneNumber,
      country_code: parseInt(orderData.personalData.countryCode),
      nombre: orderData.personalData.name,
      delivery_provider_code: orderData.providerCode,
      delivery_quote_distance_meters: orderData.delivery_quote_distance_meters,
      delivery_quote_response: orderData.delivery_quote_response,
      colonia:
        orderData.deliveryMethod === 'delivery' &&
        orderData.deliveryType !== por_kilometro &&
        orderData.deliveryType !== delivery_providers
          ? orderData.deliveryArea
            ? orderData.deliveryArea.name
            : orderData.personalData.address.zone
          : undefined,
      calle:
        orderData.deliveryMethod === 'delivery' &&
        orderData.deliveryType !== por_kilometro &&
        orderData.deliveryType !== delivery_providers
          ? orderData.personalData.address.street
          : '',
      direccion_completa:
        (orderData.deliveryMethod === 'delivery' && orderData.deliveryType === por_kilometro) ||
        orderData.deliveryType === delivery_providers
          ? orderData.personalData?.fullAddress
          : '',
      numero_exterior:
        orderData.deliveryMethod === 'delivery' &&
        orderData.deliveryType !== por_kilometro &&
        orderData.deliveryType !== delivery_providers
          ? orderData.personalData.address?.outNumber
          : '',
      numero_interior:
        orderData.deliveryMethod === 'delivery' &&
        orderData.deliveryType !== por_kilometro &&
        orderData.deliveryType !== delivery_providers
          ? orderData.personalData.address?.intNumber
          : '',
      referencias: orderData.deliveryMethod === 'delivery' ? orderData.personalData.address?.ref : '',
      link_google_maps: orderData.deliveryMethod === 'delivery' ? orderData.personalData?.mapsUrl : '',
      coords:
        orderData.deliveryMethod === 'delivery' &&
        orderData.personalData?.location?.lat &&
        orderData.personalData?.location?.lng
          ? {
              latitude: orderData.personalData.location.lat,
              longitude: orderData.personalData.location.lng,
            }
          : null,
      numero_mesa: orderData.deliveryMethod === 'inRestaurant' ? orderData.personalData?.tableNumber : undefined,
      propina: orderData.tips,
      costo_envio: deliveryPrice,
      subtotal: subtotal,
      total: total,
      comentario: orderData.comments,
      navegador: navigator.userAgent,
      productos: this.getProducts().map((prd) => ({
        id_producto: prd.id,
        id_categoria: prd.categoryId,
        presentacion: prd.option.label,
        instrucciones: prd.directions,
        nombre: prd.name,
        cantidad: prd.count,
        precio: prd.option.price,
        subtotal: prd.option.price * prd.count,
        extras: [
          ...prd.sections.map((section) => ({
            id_extra: section.id || '',
            nombre: section.label,
            cantidad: section.count * prd.count,
            precio: section.price * section.count * prd.count,
            subtotal: section.price,
          })),
          ...prd.suggestions.map((suggestion) => ({
            id_extra: suggestion.id || '',
            nombre: suggestion.label,
            cantidad: suggestion.count,
            precio: suggestion.price * suggestion.count,
            subtotal: suggestion.price,
          })),
        ],
      })),
    };

    return {
      order,
    };
  }

  registerOrder(order, originObj) {
    return new Promise((resolve, reject) => {
      ApiServices.registerOrder(order).then(
        (data) => {
          if (originObj?.origin) {
            originObj.setOrigin(null);
          }
          resolve(data);
        },
        (error) => {
          reject(error);
        },
      );
    });
  }

  order(orderData) {
    const deliveryPrice =
      orderData.deliveryMethod === 'delivery'
        ? orderData.freeDelivery
          ? 0
          : orderData.deliveryArea
            ? orderData.deliveryArea.price
            : orderData.deliveryPrice
        : 0;
    const subtotal = this.getTotal();
    const total = subtotal - orderData.discount + orderData.tips + deliveryPrice;
    const payMethod =
      orderData.payMethod.type === 'cash'
        ? 'Efectivo'
        : orderData.payMethod.type === 'card'
          ? 'Tarjeta'
          : orderData.payMethod.type === 'transfer'
            ? 'Transferencia'
            : 'Enlace de pago';
    const isPayed = payMethod === 'Enlace de pago';
    let header = this.commerceData.messageHeader;
    if (!header.includes('{nombre_cliente}')) header += '. Mi nombre es {nombre_cliente}';
    let message = `${header
      .replace('{nombre_cliente}', orderData.personalData.name)
      .replace('{nombre_comercio}', this.commerceData.name)}\n
*Número de pedido:* ${orderData.orderNumber}
  ${this.getProducts()
        .map(
          (elem) =>
            `\n  - ${elem.count} x ${elem.name} - ${elem.option.label} (${formatCurrency(elem.option.price * elem.count)})${elem.directions ? `\n  * Instrucciones Especiales: ${elem.directions}` : ''
            }${elem.sections
              .flat()
              .map(
                (section) =>
                  `\n    - ${section.count * elem.count} x ${section.label} ${section.price ? `(${formatCurrency(section.count * section.price * elem.count)})` : ''
                  }`,
              )
              .join('')}${elem.suggestions
                .flat()
                .map(
                  (suggestion) =>
                    `\n    - ${suggestion.count} x ${suggestion.label} ${suggestion.price ? `(${formatCurrency(suggestion.count * suggestion.price)})` : ''
                    }`,
                )
                .join('')}\n`,
        )
        .join('')}\n`;

    message += `*SubTotal:* ${formatCurrency(subtotal)}\n`;
    if (orderData.discount) message += `*Descuento:* -${formatCurrency(orderData.discount)}\n`;
    if (
      orderData.deliveryMethod === 'delivery' &&
      (orderData.freeDelivery || orderData.deliveryPrice || orderData.deliveryArea)
    ) {
      message += '*Envío:* ';
      message += orderData.freeDelivery
        ? `gratis por compra mayor a $${orderData.freeDeliveryAmount}`
        : `${formatCurrency(orderData.deliveryArea ? orderData.deliveryArea.price : orderData.deliveryPrice)}`;
      message += orderData.deliveryArea ? ` a ${orderData.deliveryArea.name}\n` : '\n';

      if (orderData.tips) message += `*Propina:* ${formatCurrency(orderData.tips)}\n`;
    } else if (orderData.tips) {
      message += `*Propina:* ${formatCurrency(orderData.tips)}\n`;
    }
    message += `*Total:* ${formatCurrency(total)}\n\n`;
    message += `*${isPayed ? 'Pagué' : 'Pagaré'} con:* ${(orderData.payMethod.type === 'cash' && orderData.payMethod.value
        ? `${formatCurrency(orderData.payMethod.value)} en `
        : '') + payMethod
      }
    ${orderData.comments ? `\n*Indicaciones:* ${orderData.comments}\n` : ''}
${orderData.deliveryMethod === 'delivery'
        ? `*Número de contacto:* +${orderData.personalData.countryCode}${orderData.personalData.phoneNumber}\n*Domicilio para la entrega:*\n`
        : orderData.deliveryMethod === 'takeAway'
          ? '*Pasare a recoger*'
          : '*Número de mesa:* ' + orderData.personalData.tableNumber
      }${orderData.deliveryMethod === 'delivery' &&
        orderData.deliveryType !== por_kilometro &&
        orderData.deliveryType !== delivery_providers
        ? `${orderData.personalData.address.zone ? `  Zona, col. o SMZ: ${orderData.personalData.address.zone}\n` : ''
        }${orderData.personalData.address.street ? `  Calle o mz: ${orderData.personalData.address.street}\n` : ''}${orderData.personalData.address.outNumber
          ? `  Numero exterior: ${orderData.personalData.address.outNumber}\n`
          : ''
        }${orderData.personalData.address.intNumber
          ? `  Numero interior: ${orderData.personalData.address.intNumber}\n`
          : ''
        }${orderData.personalData.address.ref
          ? `  ${orderData?.refLabel || 'Referencias'}: ${orderData.personalData.address.ref}`
          : ''
        }${orderData.personalData.mapsUrl ? `\n\n  Google Maps Url: ${orderData.personalData.mapsUrl}` : ''}`
        : ''
      }${orderData.deliveryMethod === 'delivery' &&
        (orderData.deliveryType === por_kilometro || orderData.deliveryType === delivery_providers)
        ? `${orderData.personalData.fullAddress ? `  ${orderData.personalData.fullAddress}\n` : ''}${orderData.personalData.address.ref
          ? `  ${orderData?.refLabel || 'Referencias'}: ${orderData.personalData.address.ref}`
          : ''
        }${orderData.personalData.mapsUrl ? `\n\n  Google Maps Url: ${orderData.personalData.mapsUrl}` : ''}`
        : ''
      }

${orderData.personalData.name}, tu pedido se está preparando con amor. Descubre el progreso aquí: ${ENV === 'development' ? 'http://localhost:8080' : 'https://menu.plick.app'
      }/${orderData.commerceIdPath}/order-status?folio=${orderData.orderNumber} 🧡👨‍🍳 #plick 

Muchas Gracias !`;

    ApiServices.sendWhatsappMessage(this.commerceData?.countryCode, this.commerceData.phoneNumber, message);

    this.cartList = [];
    let eventsManager = EventsManager.giveEventsManager();
    if (eventsManager) {
      eventsManager.publish('onCartModified');
      window.location.href = `/${orderData.commerceIdPath}/order-status?folio=${orderData.orderNumber}`;
    }
  }

  clear() {
    this.cartList = null;
  }
}

let instance;
export default class CartController {
  static getCartController(commerceId) {
    if (!instance) {
      let controller = new CartControllerInstance(commerceId);
      instance = Object.freeze({
        addProduct: (data) => {
          if (controller) controller.addProduct(data);
        },
        registerCommerceData: (data) => {
          if (controller) controller.registerCommerceData(data);
        },
        getProducts: () => {
          if (controller) return controller.getProducts();
        },
        getProductsLength: () => {
          if (controller) return controller.getProductsLength();
        },
        getTotal: () => {
          if (controller) return controller.getTotal();
        },
        deleteProduct: (itemId) => {
          if (controller) controller.deleteProduct(itemId);
        },
        editProduct: (itemId) => {
          if (controller) return controller.editProduct(itemId);
        },
        buildOrder: (preOrder, originObj) => {
          if (controller) return controller.buildOrder(preOrder, originObj);
        },
        registerOrder: (orderData, originObj) => {
          if (controller) return controller.registerOrder(orderData, originObj);
        },
        order: (orderData) => {
          if (controller) controller.order(orderData);
        },
        clear: () => {
          if (controller) controller.clear();
          controller = null;
        },
      });
    }
    return instance;
  }
  static giveCartController() {
    return instance;
  }
  static unreferenceCartController() {
    if (instance) instance.clear();
    instance = null;
  }
}

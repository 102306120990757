import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import StatusLabel from './elements/StatusLabel';
import '../assets/css/Header.scss';
import EventsManager from '../controllers/EventsManager';
import TabBar from './TabBar';
import { useDesktopMode } from '../hooks/hooks';
import { Icon } from '@iconify/react';
import { formatCurrency } from '../functions/money';
import ApiServices from 'ApiServices/ApiServices';

function Header({
  planId,
  title,
  logo,
  isOpen,
  categories,
  currentTabIndex,
  onItemChange,
  cartItems,
  cartTotal,
  commerceName,
  commerceID,
}) {
  const handleInfoButton = useCallback(() => {
    let eventsManager = EventsManager.giveEventsManager();
    if (eventsManager) eventsManager.publish('onInfoButtonClick');
  }, []);

  const isDesktop = useDesktopMode();

  const handleCartClick = useCallback(() => {
    if (!isOpen) {
      const ERROR = 'El comercio esta cerrado';

      ApiServices.registerWarningToMonitoring({
        commerceName: commerceName,
        commerceID: commerceID,
        errorMessage: ERROR,
        feature: 'Click en carrito',
      });

      const eventsManager = EventsManager.giveEventsManager();
      if (eventsManager) eventsManager.publish('displayNotification', { type: 'error', message: ERROR });
      return;
    }
    let eventsManager = EventsManager.giveEventsManager();
    if (eventsManager) eventsManager.publish('onCartButtonClick');
  }, [isOpen, commerceName, commerceID]);

  return (
    <div className="header">
      <div className="header-icon">
        <div className="header-logo_container">
          <img className="header-logo" alt="Logo" src={logo} />
        </div>
      </div>
      <div className="header-id_card" onClick={handleInfoButton}>
        <span className="header-title">{title}</span>
        <div className="header-info">
          <StatusLabel isOpen={isOpen} />
          <div className="header-info_button">
            <Icon icon="akar-icons:info-fill" />
            <div className="header-info_button--label">Más información</div>
          </div>
        </div>
      </div>
      <TabBar items={categories} currentIndex={currentTabIndex} onItemChange={onItemChange} />
      {isDesktop && planId && planId !== 'FREEMIUM' ? (
        <button className="header-desktop_bar_button" onClick={handleCartClick}>
          <div className="header-desktop_bar_button--icon">
            <div className="header-desktop_bar_button--items">{cartItems}</div>
            <Icon icon="uil:shopping-cart" />
          </div>
          <span className="header-desktop_bar_button--label">
            <div>Total:</div>
            <div>{formatCurrency(cartTotal)}</div>
          </span>
          <div className="header-desktop_bar_button--bar" />
          <span className="header-desktop_bar_button--next">Ver Carrito</span>
          <Icon icon="uil:angle-right" />
        </button>
      ) : null}
    </div>
  );
}

export default memo(Header);

Header.propTypes = {
  planId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  commerceName: PropTypes.string.isRequired,
  commerceID: PropTypes.string.isRequired,
  currentTabIndex: PropTypes.number.isRequired,
  cartItems: PropTypes.number.isRequired,
  cartTotal: PropTypes.number.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      id: PropTypes.string,
    }),
  ).isRequired,
  onItemChange: PropTypes.func.isRequired,
};

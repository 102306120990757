/* global API_URL */
/* global PUBLIC_API_URL */
/* global MONITORING_URL */
import axios from 'axios';

export default class ApiServices {
  static async getCommerceData(commerceIDPath) {
    let resp = await axios.get(`${API_URL}/v1/public/comercio/${commerceIDPath}`);
    return resp.data;
  }

  static async getProducts(commerceID) {
    try {
      let resp = await axios.get(`${API_URL}/v1/public/producto?id_comercio=${commerceID}`);
      return resp.data;
    } catch (error) {
      window.console.error(error);
    }
  }

  static async getProductById(productID) {
    try {
      let resp = await axios.get(`${API_URL}/v1/producto?id=${productID}`);
      return resp.data[0];
    } catch (error) {
      window.console.error(error);
    }
  }

  static async getExtrasByIds(extrasId) {
    try {
      let filter = [];
      for (let i = 0; i < extrasId.length; i++) {
        filter.push('id=' + extrasId[i]);
      }
      let resp = await axios.get(`${API_URL}/v1/public/extra?${filter.join('&')}`);
      return resp.data;
    } catch (error) {
      window.console.error(error);
    }
  }
  static sendWhatsappMessage(countryCode, number, message) {
    let fixedNumber = number;

    if (countryCode && number?.toString().startsWith(countryCode)) {
      fixedNumber = number;
    } else if (countryCode && number?.toString().startsWith(`+${countryCode}`)) {
      fixedNumber = number;
    } else if (countryCode) {
      fixedNumber = `${countryCode}${fixedNumber}`;
    }

    const linkWhatsappiOS = `https://wa.me/${fixedNumber}?text= ${encodeURIComponent(message)}`;
    const linkWhatsappOther = `whatsapp://send?text=${encodeURIComponent(
      message,
    )}&phone=${fixedNumber}&abid=${fixedNumber}`;
    window.open(linkWhatsappiOS);
    window.location.href = linkWhatsappOther;
  }
  static getImagePath(imageUrl) {
    return imageUrl;
  }

  static async getBranchOfficesData(directorio) {
    try {
      const resp = await axios.get(`${API_URL}/v1/public/directorio/${directorio}`);
      return {
        directorio: resp.data.directorio,
        comercios: resp.data.comercios,
      };
    } catch (error) {
      window.console.error(error);
    }
  }

  static async getCommerceNotification(commerceID) {
    try {
      const resp = await axios.get(`${API_URL}/v1/public/notificacion?id_comercio=${commerceID}`);
      return resp.data.aviso;
    } catch (error) {
      window.console.error(error);
    }
  }

  static async registerOrder(data) {
    const resp = await axios.post(`${API_URL}/v1/pedido`, data);
    if (resp.status !== 200) throw new Error('Order has not been saved');
    return resp.data;
  }

  static async checkPaymentStatus(orderId) {
    try {
      const resp = await axios.get(`${API_URL}/v1/public/pedido/${orderId}`);
      if (resp.status !== 200) throw new Error('Status cannot be requested');
      return resp.data;
    } catch (error) {
      window.console.error(error);
    }
  }

  static async getThirdPartyDeliveryCost({ commerceId, address, subtotal }) {
    const genericError = 'Hubo un error al obtener el costo de envío';
    try {
      let resp = await axios.post(`${PUBLIC_API_URL}/v1/delivery/quote`, {
        id_comercio: commerceId,
        address,
        subtotal: subtotal,
      });

      if (typeof resp?.data?.deliveryFeeTotal !== 'number' || Number.isNaN(resp?.data?.deliveryFeeTotal)) {
        return { error: genericError };
      }

      return resp.data;
    } catch (error) {
      return { error: error?.response?.data?.message || genericError };
    }
  }

  static async getIntent({ commerceId, amount, phone, providerCode }) {
    try {
      let resp = await axios.post(`${PUBLIC_API_URL}/v1/payment/intent`, {
        id_comercio: commerceId,
        amount,
        customer_phone: phone,
        providerCode,
      });
      if (resp.status !== 200) throw new Error('Intent cannot be requested');
      return resp.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async getExtraByCommerce(commerceId) {
    let resp = await axios.get(`${PUBLIC_API_URL}/v1/extra?id_comercio=${commerceId}`);
    return resp.data;
  }

  static async createPayment({ commerceId, amount, phone, providerCode, paymentData }) {
    try {
      let resp = await axios.post(`${PUBLIC_API_URL}/v1/payment/create`, {
        id_comercio: commerceId,
        amount,
        customer_phone: phone,
        providerCode,
        payment_data: paymentData,
      });
      if (resp.status !== 200) throw new Error('Payment cannot be requested');
      return resp.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async validateClip3DS({ paymentId, apiKey }) {
    try {
      let resp = await axios.get(`https://api.payclip.com/payments/${paymentId}`, {
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      });
      if (resp.status !== 200) throw new Error('Payment cannot be validated');
      return resp.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async registerOrderToMonitoring({ order, status, commerceID, commerceName, errorMessage, payload }) {
    try {
      await Promise.race([
        axios.post(`${MONITORING_URL}/order/register`, {
          order,
          payload,
          status,
          timestamp: new Date().valueOf(),
          errorMessage,
          menu: 'v1',
          commerce: {
            id: commerceID,
            name: commerceName,
          },
        }),
        new Promise((resolve) => setTimeout(() => resolve(), 2500)),
      ]);

      return undefined;
    } catch {
      return undefined;
    }
  }

  static async registerWarningToMonitoring({ commerceID, commerceName, errorMessage, feature }) {
    try {
      await Promise.race([
        axios.post(`${MONITORING_URL}/error/register`, {
          timestamp: new Date().valueOf(),
          errorMessage,
          feature,
          menu: 'v1',
          commerce: {
            id: commerceID,
            name: commerceName,
          },
        }),
        new Promise((resolve) => setTimeout(() => resolve(), 2500)),
      ]);

      return undefined;
    } catch {
      return undefined;
    }
  }
}

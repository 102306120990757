import React, { useState, useCallback, useEffect, useRef } from 'react';
import Header from 'Components/Header';
import ProductCard from 'Components/ProductCard';
import PropTypes from 'prop-types';
import ApiServices from 'ApiServices/ApiServices';
import EventsManager from 'Controllers/EventsManager';
import CartController from 'Controllers/CartController';
import 'Styles/HomePage.scss';
import BarButton from 'Elements/BarButton';
import { Icon } from '@iconify/react';
import { useDesktopMode } from 'Hooks/hooks';
import logoPlick from 'Images/logo.svg';
import Search from 'Elements/Search';
import { formatCurrency } from '../functions/money';
import { OrderInProgress } from '../components/elements/OrderInProgress';

let scrollTimer,
  animateScroll = false;

function HomePage({ data }) {
  const { id, planId, name, logo, isOpen, categories } = data;
  const [products, setProducts] = useState([]);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [searchText, setSearchText] = useState('');
  const isDesktop = useDesktopMode();

  const headerWidth = isDesktop ? 80 : 150;
  const wrapperRef = useRef(null);

  const handleCartClick = useCallback(() => {
    if (!isOpen) {
      ApiServices.registerWarningToMonitoring({
        commerceName: name,
        commerceID: id,
        errorMessage: 'El comercio esta cerrado',
        feature: 'Click en carrito',
      });
      const eventsManager = EventsManager.giveEventsManager();
      if (eventsManager)
        eventsManager.publish('displayNotification', { type: 'error', message: 'El comercio esta cerrado' });
      return;
    }
    let eventsManager = EventsManager.giveEventsManager();
    if (eventsManager) eventsManager.publish('onCartButtonClick');
  }, [isOpen, name, id]);

  const handleProductClick = useCallback((product) => {
    let eventsManager = EventsManager.giveEventsManager();
    if (eventsManager) eventsManager.publish('onAddProductPageRequest', product);
  }, []);

  const handleItemChange = useCallback(
    (index) => {
      if (currentTabIndex !== index) {
        animateScroll = true;
        setCurrentTabIndex(index);
      }
    },
    [currentTabIndex],
  );

  const handleSearch = useCallback((value) => {
    setSearchText(value);
  }, []);

  const handleScroll = useCallback(() => {
    clearTimeout(scrollTimer);
    scrollTimer = setTimeout(() => {
      scrollTimer = null;
      if (wrapperRef.current) {
        let sections = wrapperRef.current.querySelectorAll('.home_page-section-separator');
        let index = Array.prototype.findIndex.call(
          sections,
          (section) => section.offsetTop - window.pageYOffset >= headerWidth + 5,
        );
        if (index === -1) index = sections.length;
        index--;
        index = Math.max(0, index);
        while (index >= 0 && sections[index].style.display === 'none') index--;
        setCurrentTabIndex(index);
      }
    }, 50);
  }, []);

  useEffect(() => {
    let productObjects, productDisplay;
    (async () => {
      try {
        if (id) {
          const productsData = await ApiServices.getProducts(id);
          if (productsData) {
            productObjects = productsData.map((producto) => {
              const firstAvailableOptionIdx = producto.opciones.findIndex((opt) => opt.estatus === 'activo');
              return {
                id: producto.id,
                categoryId: producto.id_categoria,
                name: producto.nombre,
                image: producto.imagen,
                description: producto.descripcion,
                isDisabled: producto.estatus !== 'activo' || producto.opciones.every((opt) => opt.estatus !== 'activo'),
                availableDays: producto.dias_disponibles,
                options: producto.opciones.map((opcion, idx) => ({
                  label: opcion.presentacion,
                  isDisabled: opcion.estatus !== 'activo',
                  price: opcion.precio,
                  count: idx === firstAvailableOptionIdx ? 1 : 0,
                })),
                sections: producto.secciones
                  .filter((seccion) => seccion.es_multiplo !== 'simple')
                  .map((seccion) => ({
                    title: seccion.nombre,
                    isRequired: seccion.obligatorio,
                    isDisabled: seccion.estatus !== 'activo',
                    maxValues: seccion.numero_de_opciones,
                    type: seccion.tipo,
                    content: seccion.opciones,
                  })),
                suggestions: producto.secciones
                  .filter((seccion) => seccion.es_multiplo === 'simple')
                  .map((seccion) => ({
                    title: seccion.nombre,
                    isDisabled: seccion.estatus !== 'activo',
                    maxValues: seccion.numero_de_opciones,
                    type: seccion.tipo,
                    content: seccion.opciones,
                  })),
              };
            });
            setProducts(productObjects);
            window.addEventListener('scroll', handleScroll);
            let eventsManager = EventsManager.giveEventsManager();
            if (eventsManager) {
              eventsManager.publish('onApplicationLoadingComplete');
            }
          }
        }
      } catch (error) {
        window.console.error(error);
      }
    })();

    let eventsManager = EventsManager.giveEventsManager();
    if (eventsManager) {
      productDisplay = eventsManager.subscribe('onDisplayProductRequest', (productId) => {
        let product = productObjects.find((prod) => prod.id === productId);
        if (product) eventsManager.publish('onAddProductPageRequest', product);
      });
    }

    return () => {
      if (eventsManager && productDisplay) eventsManager.unsubscribe(productDisplay);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [id]);

  useEffect(() => {
    let orderDisplay;
    let eventsManager = EventsManager.giveEventsManager();
    if (eventsManager) {
      orderDisplay = eventsManager.subscribe('onDisplayOrderStatusRequest', (orderId) => {
        if (data.activeOrders.find((o) => o.orderId === orderId)) {
          eventsManager.publish('onOrderStatusPageRequest', orderId);
        }
      });
    }

    return () => {
      if (eventsManager && orderDisplay) eventsManager.unsubscribe(orderDisplay);
    };
  }, [data.activeOrders]);

  useEffect(() => {
    let tokenUpdate;
    let eventsManager = EventsManager.giveEventsManager();
    if (eventsManager) {
      tokenUpdate = eventsManager.subscribe('onProductAvailabilityChanged', (productId) => {
        let product = products.find((prod) => prod.id === productId);
        if (product) {
          product.isDisabled = !product.isDisabled;
          setProducts([...products]);
        }
      });
    }
    return () => {
      if (eventsManager && tokenUpdate) eventsManager.unsubscribe(tokenUpdate);
    };
  }, [products]);

  useEffect(() => {
    if (wrapperRef.current && animateScroll) {
      animateScroll = false;
      let sections = wrapperRef.current.querySelectorAll('.home_page-section-separator');
      if (sections[currentTabIndex].style.display !== 'none') {
        let sectionTopPosition = sections[currentTabIndex].offsetTop;
        window.scrollTo({
          top: sectionTopPosition - headerWidth,
          behavior: 'smooth',
        });
      }
    }
  }, [currentTabIndex]);

  let productsToDisplay = searchText
    ? products.filter(
        (product) =>
          product.name.toLowerCase().includes(searchText) || product.description.toLowerCase().includes(searchText),
      )
    : products;
  const cartController = CartController.giveCartController();
  let cartItems = cartController ? cartController.getProductsLength() : 0;
  let cartTotal = cartController ? cartController.getTotal() : 0;
  let filteredCategories = categories.filter((c) => products.some((p) => p.categoryId === c.id));
  return (
    <>
      {isDesktop && (
        <div className="home_page-desktop_header">
          <div className="home_page-desktop_header--logo">
            <img src={logoPlick} alt="logo plick" height="25px" />
            <span>
              Pedidos a un <mark>click.</mark>
            </span>
          </div>
          <div className="home_page-search">
            <Search value={searchText} onSearchChange={handleSearch} />
          </div>
          {data.activeOrders.length > 0 && <OrderInProgress />}
        </div>
      )}
      <Header
        planId={planId}
        title={name}
        isOpen={isOpen}
        logo={logo}
        categories={filteredCategories}
        currentTabIndex={currentTabIndex}
        onItemChange={handleItemChange}
        cartItems={cartItems}
        cartTotal={cartTotal}
        commerceName={name}
        commerceID={id}
      />
      <div className="home_page">
        <div ref={wrapperRef} className="home_page-wrapper">
          {!isDesktop && (
            <div className="home_page-search">
              <Search value={searchText} onSearchChange={handleSearch} />
            </div>
          )}
          {filteredCategories.map((category, idx) => {
            let filteredProducts = productsToDisplay.filter((product) => product.categoryId === category.id);
            return (
              <React.Fragment key={'fragment-' + idx}>
                <div
                  key={'section-' + category.id}
                  className="home_page-section-separator"
                  style={{ display: filteredProducts.length ? '' : 'none' }}
                >
                  {category.label}
                </div>
                {filteredProducts.map((product) => (
                  <ProductCard
                    key={product.id}
                    title={product.name}
                    description={product.description}
                    price={product.options && product.options[0] && product.options[0].price}
                    imageUrl={ApiServices.getImagePath(product.image)}
                    onClick={() => handleProductClick(product)}
                  />
                ))}
              </React.Fragment>
            );
          })}
        </div>
      </div>
      {!isDesktop && planId && planId !== 'FREEMIUM' ? (
        <BarButton onClick={handleCartClick} disabled={!isOpen} activeOrders={data.activeOrders}>
          {cartItems ? (
            <div className="home_page-bar_button">
              <div className="home_page-bar_button--icon">
                <div className="home_page-bar_button--items">{cartItems}</div>
                <Icon icon="uil:shopping-cart" />
              </div>
              <span className="home_page-bar_button--label">{`Total: ${formatCurrency(cartTotal)}`}</span>
              <div className="home_page-bar_button--bar" />
              <span className="home_page-bar_button--next">Ver Carrito</span>
              <Icon icon="uil:angle-right" />
            </div>
          ) : null}
        </BarButton>
      ) : null}
    </>
  );
}

export default HomePage;

HomePage.propTypes = {
  data: PropTypes.exact({
    id: PropTypes.string.isRequired,
    planId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    schedule: PropTypes.arrayOf(
      PropTypes.exact({
        dia_semana: PropTypes.string.isRequired,
        horario_apertura: PropTypes.string.isRequired,
        horario_cierre: PropTypes.string.isRequired,
        nombre_horario: PropTypes.string,
      }),
    ).isRequired,
    categories: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ),
    activeOrders: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.string.isRequired,
        orderId: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        deliveryMethod: PropTypes.string.isRequired,
        deliveryType: PropTypes.string,
        hour: PropTypes.string.isRequired,
        deliveryDriverInfo: PropTypes.exact({
          name: PropTypes.string.isRequired,
          email: PropTypes.string,
          deliveryTrackingId: PropTypes.string,
          delivery_tracking_url: PropTypes.string,
          phone: PropTypes.string.isRequired,
          image: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
        }),
        deliveryTrackingUrl: PropTypes.string,
        paymentMethod: PropTypes.string,
        orderTotal: PropTypes.number,
      }),
    ).isRequired,
  }),
};

import EventsManager from 'Controllers/EventsManager';
import ApiServices from 'ApiServices/ApiServices';
import { statusDetails } from './statusDetails';

export const mercadoPagoCheckout = async ({
  createOrder,
  setIsCreatingOrder,
  formData,
  commerceId,
  commerceName,
  amount,
  phone,
  providerCode,
}) => {
  const genericError = 'No se pudo procesar el pago, intente nuevamente';
  try {
    if (!formData) {
      throw new Error(genericError);
    }

    const response = await ApiServices.createPayment({
      commerceId,
      amount,
      phone,
      providerCode,
      paymentData: formData,
    });

    if (response?.status !== 'approved' || !response?.id) {
      const detail = statusDetails[response?.status_detail] || genericError;

      await ApiServices.registerWarningToMonitoring({
        commerceName,
        commerceID: commerceId,
        errorMessage: detail,
        feature: 'Mercado Pago',
      });

      const eventsManager = EventsManager.giveEventsManager();
      if (eventsManager) {
        eventsManager.publish('displayNotification', {
          type: 'error',
          message: detail,
        });
      }

      return;
    }
  } catch (e) {
    await ApiServices.registerWarningToMonitoring({
      commerceName,
      commerceID: commerceId,
      errorMessage: genericError,
      feature: 'Mercado Pago',
    });

    const eventsManager = EventsManager.giveEventsManager();
    if (eventsManager) {
      eventsManager.publish('displayNotification', {
        type: 'error',
        message: genericError,
      });
    }

    return;
  }

  setIsCreatingOrder(true);
  createOrder();
};

import React from 'react';
import RoundedRectangleButton from './elements/RoundedRectangleButton';
import EventsManager from 'Controllers/EventsManager';
import PropTypes from 'prop-types';
import { copyToClipboard } from '../utils/clipboard';
import ApiServices from 'ApiServices/ApiServices';

export const BankingInformation = ({ name, bank, clabe, commerceName, commerceID }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
      <p style={{ display: 'flex', flexDirection: 'column' }}>
        <b>Nombre:</b>
        <span>{name}</span>
      </p>
      <p>
        <b>Banco:</b> {bank}
      </p>
      <p>
        <b>CLABE:</b> {clabe}
      </p>
      <RoundedRectangleButton
        className="submit_button full"
        icon="grommet-icons:copy"
        label="Copiar CLABE"
        onClick={() => {
          const eventsManager = EventsManager.giveEventsManager();
          try {
            copyToClipboard(clabe);
            if (eventsManager)
              eventsManager.publish('displayNotification', {
                type: 'info',
                message: 'CLABE copiada al portapapeles',
              });
          } catch (error) {
            const ERROR = 'No se pudo copiar la CLABE, verifica el accesso al portapapeles';

            ApiServices.registerWarningToMonitoring({
              commerceName: commerceName,
              commerceID: commerceID,
              errorMessage: ERROR,
              feature: 'Datos de Transferencia',
            });

            if (eventsManager)
              eventsManager.publish('displayNotification', {
                type: 'error',
                message: ERROR,
              });
          }
        }}
      />
    </div>
  );
};

BankingInformation.propTypes = {
  name: PropTypes.string,
  bank: PropTypes.string,
  clabe: PropTypes.string,
  commerceName: PropTypes.string,
  commerceID: PropTypes.string,
};

import React, { useEffect } from 'react';
import { useLoadClip } from './useLoadClip';
import { useCreateForm } from './useCreateForm';
import { Icon } from '@iconify/react';
import { ClipButton } from './ClipButton';
import { useClipCheckout } from './useClipCheckout';
import { PayDisclaimer } from '../PayDisclaimer';
import { usePostHog } from 'posthog-js/react';
// @ts-ignore
import useTracking, { TRACK_EVENT_NAMES } from '../../../hooks/useTracking.js';
// @ts-ignore
import ApiServices from '../../../services/ApiServices.js';
// @ts-ignore
import EventsManager from '../../../controllers/EventsManager';
import { statusDetails } from './statusDetails';

const ClipFormUniqueID = 'clip-form';

type ClipCardProps = {
  total: number;
  createOrder: () => void;
  commerceId: string;
  commerceName: string;
  providerCode: string;
  customerPhone: string;
  paymentProviderKey: string;
  customerOnlyPhone: string;
};

export const ClipCard = ({
  paymentProviderKey,
  total,
  createOrder,
  commerceId,
  commerceName,
  providerCode,
  customerPhone,
  customerOnlyPhone,
}: ClipCardProps) => {
  const { isJSLoaded } = useLoadClip();
  const { trackEvent } = useTracking();
  const posthog = usePostHog();
  const { isFormCreated, isError, clipCard } = useCreateForm({
    isJSLoaded,
    formID: ClipFormUniqueID,
    key: paymentProviderKey,
  });
  const { isCheckingOut, handleCheckout, iframeUrl, setIsCheckingOut, setIframeUrl } = useClipCheckout();
  const [authFailed, setAuthFailed] = React.useState(false);

  const eventListener = async (event: any) => {
    const eventsManager = EventsManager.giveEventsManager();
    if (event.origin === 'https://3ds.payclip.com') {
      const paymentId = event.data?.paymentId;
      if (paymentId) {
        setIsCheckingOut(true);
        setIframeUrl(null);

        try {
          const response = await ApiServices.validateClip3DS({
            paymentId,
            apiKey: paymentProviderKey,
          });

          if (response?.status === 'approved') {
            createOrder();
            return;
          } else {
            const detail =
              statusDetails[response?.status_detail?.code as keyof typeof statusDetails] ||
              'No se pudo realizar tu pago';

            ApiServices.registerWarningToMonitoring({
              commerceName: commerceName,
              commerceID: commerceId,
              errorMessage: detail,
              feature: 'Clip',
            });

            trackEvent(TRACK_EVENT_NAMES.ThreeDSecureFailed, {
              data: event.data,
              error: response.error,
            });
            if (eventsManager) {
              eventsManager.publish('displayNotification', {
                type: 'error',
                message: detail,
              });
            }

            setAuthFailed(true);
          }
        } catch (e) {
          ApiServices.registerWarningToMonitoring({
            commerceName: commerceName,
            commerceID: commerceId,
            errorMessage: 'No se pudo generar tu pago',
            feature: 'Clip',
          });
          trackEvent(TRACK_EVENT_NAMES.ThreeDSecureFailed, {
            data: event.data,
            error: e,
          });
          if (eventsManager) {
            eventsManager.publish('displayNotification', {
              type: 'error',
              message: 'No se pudo generar tu pago',
            });
          }
          setAuthFailed(true);
        }
      } else {
        ApiServices.registerWarningToMonitoring({
          commerceName: commerceName,
          commerceID: commerceId,
          errorMessage: 'No hay paymentId en la respuesta',
          feature: 'Clip',
        });

        trackEvent(TRACK_EVENT_NAMES.ThreeDSecureFailed, {
          data: event.data,
          error: event.data?.error,
        });
        if (eventsManager) {
          eventsManager.publish('displayNotification', {
            type: 'error',
            message: 'No se pudo generar tu pago',
          });
        }

        setAuthFailed(true);
      }
    }
  };

  useEffect(() => {
    if (
      !setIsCheckingOut ||
      !trackEvent ||
      !setAuthFailed ||
      !setIframeUrl ||
      !createOrder ||
      !posthog ||
      !ApiServices?.validateClip3DS
    ) {
      return;
    }

    window.addEventListener(`message`, eventListener);

    return () => {
      window.removeEventListener(`message`, eventListener);
    };
  }, [setIsCheckingOut, trackEvent, setAuthFailed, setIframeUrl, createOrder, posthog, ApiServices?.validateClip3DS]);

  if (isError || authFailed) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <p style={{ color: 'red', textAlign: 'center' }}>
          No se pudo generar tu pago, intenta con otro método, si el problema persiste contacta al comercio
        </p>
      </div>
    );
  }

  return (
    <div>
      <div
        style={{
          display: !isJSLoaded || !isFormCreated || isCheckingOut ? 'flex' : 'none',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        {isCheckingOut ? 'Estamos enviando tu pedido...' : null}
        <Icon icon="line-md:loading-twotone-loop" width={40} />
      </div>
      <form id="payment-form" style={{ display: isFormCreated && !isCheckingOut && !iframeUrl ? 'block' : 'none' }}>
        <div id={ClipFormUniqueID}></div>
        <div>
          <ClipButton
            total={total}
            onClick={() =>
              handleCheckout({
                createOrder,
                providerCode,
                commerceId,
                commerceName,
                amount: total,
                phone: customerPhone,
                customerOnlyPhone: customerOnlyPhone,
                clipCard,
              })
            }
          />
        </div>
        <PayDisclaimer />
      </form>
      {iframeUrl ? <iframe style={{ width: '100%', height: '450px' }} title="cybersource3Ds" src={iframeUrl} /> : null}
    </div>
  );
};
